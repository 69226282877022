"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.trackSearchResultClicked = exports.trackSearchTerm = exports.trackFeedbackSubmit = exports.trackNotHelpfulBtn = exports.trackContinueToFormClicked = exports.trackQuestionClicked = exports.trackShowMoreFAQsClicked = void 0;
var use_polly_1 = require("@/composables/use-polly");
var pushGAevent_1 = require("@/services/pushGAevent");
var vue_1 = require("vue");
var currentTopic = (0, vue_1.computed)(function () { var _a; return ((_a = use_polly_1.currentCollection.value) === null || _a === void 0 ? void 0 : _a.name) || ""; });
// Please keep the confluence documentation up-to-date: https://redbox.outletcity.com/confluence/display/TUB/FR+-+Custom-Tracking
var trackShowMoreFAQsClicked = function () {
    (0, pushGAevent_1.pushGAevent)("faq_show_more", currentTopic.value);
};
exports.trackShowMoreFAQsClicked = trackShowMoreFAQsClicked;
var trackQuestionClicked = function (questionName) {
    (0, pushGAevent_1.pushGAevent)("faq_clicked", "".concat(currentTopic.value, "_").concat(questionName));
};
exports.trackQuestionClicked = trackQuestionClicked;
var trackContinueToFormClicked = function () {
    (0, pushGAevent_1.pushGAevent)("proceed_to_contactform", currentTopic.value);
};
exports.trackContinueToFormClicked = trackContinueToFormClicked;
var trackNotHelpfulBtn = function (articleName) {
    (0, pushGAevent_1.pushGAevent)("faq_not_helpful_preselect", "".concat(currentTopic.value, "_").concat(articleName));
};
exports.trackNotHelpfulBtn = trackNotHelpfulBtn;
var trackFeedbackSubmit = function (articleName, positiveFeedback) {
    var eventAction = positiveFeedback ? "faq_helpful_submit" : "faq_not_helpful_submit";
    (0, pushGAevent_1.pushGAevent)(eventAction, "".concat(currentTopic.value, "_").concat(articleName));
};
exports.trackFeedbackSubmit = trackFeedbackSubmit;
var trackSearchTerm = function (searchTerm, searchResult) {
    (0, pushGAevent_1.pushGAevent)("faq_search_submit", "".concat(searchTerm, ": ").concat(searchResult));
};
exports.trackSearchTerm = trackSearchTerm;
var trackSearchResultClicked = function (searchTerm, answerText) {
    (0, pushGAevent_1.pushGAevent)("faq_search_result_clicked", "".concat(searchTerm, "_").concat(answerText));
};
exports.trackSearchResultClicked = trackSearchResultClicked;
