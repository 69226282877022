"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = { class: "text-black" };
var _hoisted_2 = { class: "absolute right-4" };
var _hoisted_3 = { key: 0 };
var _hoisted_4 = {
    key: 0,
    class: "pointer-events-none absolute top-0 flex min-h-[60px] w-full items-center justify-between p-4"
};
var vue_3 = require("vue");
var vue_i18n_1 = require("vue-i18n");
var SvgIcon_vue_1 = require("@SharedVueComponents/SvgIcon/SvgIcon.vue");
var SharedSpinner_vue_1 = require("@SharedVueComponents/SharedSpinner.vue");
var use_imageUpload_1 = require("@/composables/use-imageUpload");
var use_trackingErrors_1 = require("@/composables/use-trackingErrors");
exports.default = (0, vue_1.defineComponent)({
    __name: 'FormKitImage',
    props: {
        modelValue: {},
        label: {},
        name: {},
        validation: {}
    },
    emits: ["update:modelValue"],
    setup: function (__props, _a) {
        var emit = _a.emit;
        var props = __props;
        var t = (0, vue_i18n_1.useI18n)().t;
        var hasWrongTypeError = (0, vue_3.ref)(false);
        var isCompressing = (0, vue_3.ref)(false);
        var wrongFileTypeErrorMessage = t("imageUploadPage.errorFileTyp", [use_imageUpload_1.acceptedFiles]);
        var handleChange = function (event) {
            hasWrongTypeError.value = !isValidFileType(event.target.value);
            hasWrongTypeError.value
                ? use_imageUpload_1.invalidTypeFieldNames.value.add(props.name)
                : use_imageUpload_1.invalidTypeFieldNames.value.delete(props.name);
            if (hasWrongTypeError.value)
                (0, use_trackingErrors_1.trackImageWrongType)(t);
        };
        var handleDelete = function () {
            hasWrongTypeError.value = false;
            theModel.value = [];
            use_imageUpload_1.invalidTypeFieldNames.value.delete(props.name);
        };
        var isValidFileType = function (fileNameParam) {
            var _a;
            var fileName = fileNameParam || ((_a = theModel.value[0]) === null || _a === void 0 ? void 0 : _a.name);
            var acceptedFileTypes = use_imageUpload_1.acceptedFiles.split(",").map(function (str) { return str.trim().replace(".", ""); });
            var fileType = fileName.split(".").pop() || "";
            if (acceptedFileTypes.includes(fileType)) {
                return true;
            }
            return false;
        };
        var theModel = (0, vue_3.computed)({
            get: function () { return props.modelValue; },
            set: function (value) { return emit("update:modelValue", value); },
        });
        (0, vue_3.watch)(theModel, function () {
            compressImageLocal();
        });
        function compressImageLocal() {
            return __awaiter(this, void 0, void 0, function () {
                var image;
                return __generator(this, function (_a) {
                    image = theModel.value[0].file;
                    if (!image)
                        return [2 /*return*/];
                    setIsCompressing(true);
                    (0, use_imageUpload_1.compressImage)(image)
                        .then(function (compressedImage) {
                        if (compressedImage) {
                            theModel.value[0].file = compressedImage;
                            setIsCompressing(false);
                        }
                    })
                        .catch(function (err) {
                        console.error(err);
                        (0, use_trackingErrors_1.trackImageCompressionError)(image.size, getFileType(image.name));
                        setIsCompressing(false);
                    });
                    return [2 /*return*/];
                });
            });
        }
        function setIsCompressing(value) {
            isCompressing.value = value;
            use_imageUpload_1.isAnyImageCompressing.value = value;
        }
        function getFileType(fileName) {
            if (!fileName)
                return null;
            var parts = fileName.split(".");
            return parts.length > 1 ? parts.pop() : null;
        }
        return function (_ctx, _cache) {
            var _component_FormKit = (0, vue_2.resolveComponent)("FormKit");
            return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_FormKit, {
                modelValue: theModel.value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) { return ((theModel).value = $event); }),
                name: _ctx.name,
                type: "file",
                label: _ctx.label,
                accept: (0, vue_2.unref)(use_imageUpload_1.acceptedFiles),
                validation: _ctx.validation,
                errors: [hasWrongTypeError.value ? (0, vue_2.unref)(wrongFileTypeErrorMessage) : ''],
                onChange: handleChange
            }, {
                fileName: (0, vue_2.withCtx)(function (context) {
                    var _a;
                    return [
                        (!isCompressing.value && (context === null || context === void 0 ? void 0 : context._value[0]))
                            ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("span", {
                                key: 0,
                                class: (0, vue_2.normalizeClass)(["overflow-hidden text-ellipsis whitespace-nowrap", { 'border-error': (0, vue_2.unref)(use_imageUpload_1.imageUploadSizeOverLimit) || hasWrongTypeError.value }])
                            }, [
                                (0, vue_2.createElementVNode)("span", {
                                    class: (0, vue_2.normalizeClass)((0, vue_2.unref)(use_imageUpload_1.imageUploadSizeOverLimit) ? 'text-error' : 'text-gray-80')
                                }, (0, vue_2.toDisplayString)((0, vue_2.unref)(use_imageUpload_1.formatFileSize)((_a = context._value[0]) === null || _a === void 0 ? void 0 : _a.file.size)) + " - ", 3),
                                (0, vue_2.createElementVNode)("span", _hoisted_1, (0, vue_2.toDisplayString)(context._value[0].name), 1)
                            ], 2))
                            : (0, vue_2.createCommentVNode)("", true),
                        (0, vue_2.createElementVNode)("span", _hoisted_2, [
                            (isCompressing.value)
                                ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("span", _hoisted_3, [
                                    (0, vue_2.createVNode)(SharedSpinner_vue_1.default, {
                                        class: "",
                                        "dark-spinner": ""
                                    })
                                ]))
                                : ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(SvgIcon_vue_1.default, {
                                    key: 1,
                                    "icon-name": "close",
                                    class: (0, vue_2.normalizeClass)(["text-black", { 'text-error': (0, vue_2.unref)(use_imageUpload_1.imageUploadSizeOverLimit) || hasWrongTypeError.value }])
                                }, null, 8, ["class"]))
                        ])
                    ];
                }),
                noFiles: (0, vue_2.withCtx)(function (context) { return [
                    (!context._value[0])
                        ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_4, [
                            (0, vue_2.createVNode)(SvgIcon_vue_1.default, {
                                "icon-name": "upload",
                                class: "mr-2 text-xl"
                            }),
                            (0, vue_2.createVNode)(SvgIcon_vue_1.default, {
                                "icon-name": "plus-thin",
                                class: "ml-2 text-xl"
                            })
                        ]))
                        : (0, vue_2.createCommentVNode)("", true)
                ]; }),
                fileRemove: (0, vue_2.withCtx)(function () { return [
                    (0, vue_2.createElementVNode)("button", {
                        type: "button",
                        class: "formkit-file-remove absolute right-4 h-5 w-5 opacity-0",
                        onClick: handleDelete
                    }, [
                        (0, vue_2.createVNode)(SvgIcon_vue_1.default, {
                            "aria-label": "Entfernen",
                            "icon-name": "close",
                            class: (0, vue_2.normalizeClass)(["absolute right-4 text-black", { 'text-error': (0, vue_2.unref)(use_imageUpload_1.imageUploadSizeOverLimit) || hasWrongTypeError.value }])
                        }, null, 8, ["class"])
                    ])
                ]; }),
                _: 1
            }, 8, ["modelValue", "name", "label", "accept", "validation", "errors"]));
        };
    }
});
