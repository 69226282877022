"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var use_transition_expand_duration_1 = require("../../composables/use-transition-expand-duration");
exports.default = (0, vue_1.defineComponent)({
    __name: 'TransitionExpand',
    emits: ["afterLeave"],
    setup: function (__props, _a) {
        var emit = _a.emit;
        var enter = function (element) {
            element.style.height = "auto";
            var height = getComputedStyle(element).height;
            element.style.height = "0";
            getComputedStyle(element).height;
            requestAnimationFrame(function () {
                element.style.height = height;
            });
        };
        var afterEnter = function (element) { return (element.style.height = "auto"); };
        var leave = function (element) {
            element.style.height = getComputedStyle(element).height;
            getComputedStyle(element).height;
            requestAnimationFrame(function () {
                element.style.height = "0";
            });
        };
        var onAfterLeave = function () {
            emit("afterLeave");
        };
        return function (_ctx, _cache) {
            return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(vue_2.Transition, {
                "enter-active-class": "overflow-hidden transition-height duration-".concat((0, vue_2.unref)(use_transition_expand_duration_1.durationTimeInMs)),
                "leave-active-class": "overflow-hidden transition-height duration-".concat((0, vue_2.unref)(use_transition_expand_duration_1.durationTimeInMs)),
                onEnter: enter,
                onAfterEnter: afterEnter,
                onLeave: leave,
                onAfterLeave: onAfterLeave
            }, {
                default: (0, vue_2.withCtx)(function () { return [
                    (0, vue_2.renderSlot)(_ctx.$slots, "default")
                ]; }),
                _: 3
            }, 8, ["enter-active-class", "leave-active-class"]));
        };
    }
});
