"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = { class: "mb-20 grid grid-cols-[min-content,1fr] items-center gap-x-2" };
var SvgIcon_vue_1 = require("@SharedVueComponents/SvgIcon/SvgIcon.vue");
var InfoModal_vue_1 = require("@SharedVueComponents/Modal/InfoModal.vue");
var HeadlineText_vue_1 = require("@/components/HeadlineText.vue");
var use_pageNavigation_1 = require("@/composables/use-pageNavigation");
var use_trackingErrors_1 = require("@/composables/use-trackingErrors");
var use_messageData_1 = require("@/composables/use-messageData");
var vue_3 = require("vue");
var use_formValidity_1 = require("@/composables/use-formValidity");
exports.default = (0, vue_1.defineComponent)({
    __name: 'OrderPage',
    setup: function (__props) {
        var formRef = (0, vue_3.ref)();
        (0, vue_3.onMounted)(function () {
            (0, use_formValidity_1.useRerouteToValidStep)(formRef);
        });
        return function (_ctx, _cache) {
            var _component_FormKit = (0, vue_2.resolveComponent)("FormKit");
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", null, [
                (0, vue_2.createVNode)(HeadlineText_vue_1.default, null, {
                    default: (0, vue_2.withCtx)(function () { return [
                        (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(_ctx.$t("orderPage.headline")), 1)
                    ]; }),
                    _: 1
                }),
                (0, vue_2.createVNode)(_component_FormKit, {
                    ref_key: "formRef",
                    ref: formRef,
                    type: "form",
                    "submit-label": _ctx.$t('next'),
                    config: { validationVisibility: 'submit' },
                    onSubmit: _cache[1] || (_cache[1] = function ($event) { return ((0, vue_2.unref)(use_pageNavigation_1.goToNextFormStep)()); })
                }, {
                    default: (0, vue_2.withCtx)(function () { return [
                        (0, vue_2.createVNode)(_component_FormKit, {
                            modelValue: (0, vue_2.unref)(use_messageData_1.orderNumber),
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) { return ((0, vue_2.isRef)(use_messageData_1.orderNumber) ? (use_messageData_1.orderNumber).value = $event : null); }),
                            name: "Bestellnummer",
                            label: "Bestellnummer",
                            type: "ocmTextInput",
                            "input-type": "number",
                            plugins: [(0, vue_2.unref)(use_trackingErrors_1.useErrorTracking)().trackingPlugin],
                            validation: "required|matches:/^[2-4][0-9]{7}$/",
                            "validation-messages": {
                                matches: _ctx.$t('orderPage.lengthErrorMessage'),
                            }
                        }, null, 8, ["modelValue", "plugins", "validation-messages"]),
                        (0, vue_2.createElementVNode)("div", _hoisted_1, [
                            (0, vue_2.createVNode)(SvgIcon_vue_1.default, {
                                "icon-name": "info",
                                class: "h-4.5 w-4.5"
                            }),
                            (0, vue_2.createVNode)(InfoModal_vue_1.default, {
                                headline: _ctx.$t('orderPage.helpHeadline')
                            }, {
                                trigger: (0, vue_2.withCtx)(function () { return [
                                    (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(_ctx.$t("orderPage.helpHeadline")), 1)
                                ]; }),
                                default: (0, vue_2.withCtx)(function () { return [
                                    (0, vue_2.createTextVNode)(" " + (0, vue_2.toDisplayString)(_ctx.$t("orderPage.helpText")), 1)
                                ]; }),
                                _: 1
                            }, 8, ["headline"])
                        ])
                    ]; }),
                    _: 1
                }, 8, ["submit-label"])
            ]));
        };
    }
});
