"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = {
    key: 1,
    class: "mb-10 flex items-center text-error"
};
var ocm_object_1 = require("@ocm/services/ocm-object");
var HeadlineText_vue_1 = require("@/components/HeadlineText.vue");
var FormKitImage_vue_1 = require("@/components/FormKitImage.vue");
var TransitionExpand_vue_1 = require("@/components/TransitionExpand.vue");
var use_imageUpload_1 = require("@/composables/use-imageUpload");
var use_trackingErrors_1 = require("@/composables/use-trackingErrors");
var SvgIcon_vue_1 = require("@SharedVueComponents/SvgIcon/SvgIcon.vue");
var use_messageData_1 = require("@/composables/use-messageData");
var use_pageNavigation_1 = require("@/composables/use-pageNavigation");
var vue_3 = require("vue");
var vue_i18n_1 = require("vue-i18n");
var use_formValidity_1 = require("@/composables/use-formValidity");
exports.default = (0, vue_1.defineComponent)({
    __name: 'ImageUploadPage',
    setup: function (__props) {
        var t = (0, vue_i18n_1.useI18n)().t;
        var formRef = (0, vue_3.ref)();
        (0, vue_3.onMounted)(function () {
            (0, use_formValidity_1.useRerouteToValidStep)(formRef);
        });
        var submitHandler = function () {
            if (use_imageUpload_1.imageUploadSizeOverLimit.value || use_imageUpload_1.invalidTypeFieldNames.value.size > 0)
                return;
            (0, use_pageNavigation_1.goToNextFormStep)();
        };
        (0, vue_3.watch)(use_imageUpload_1.imageUploadSizeOverLimit, function () {
            if (use_imageUpload_1.imageUploadSizeOverLimit.value)
                (0, use_trackingErrors_1.trackImageSizeToLarge)(t);
        });
        return function (_ctx, _cache) {
            var _component_FormKit = (0, vue_2.resolveComponent)("FormKit");
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", null, [
                (0, vue_2.createVNode)(HeadlineText_vue_1.default, null, {
                    default: (0, vue_2.withCtx)(function () { return [
                        (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(_ctx.$t("imageUploadPage.headline")), 1)
                    ]; }),
                    _: 1
                }),
                (0, vue_2.createVNode)(_component_FormKit, {
                    id: "imageForm",
                    ref_key: "formRef",
                    ref: formRef,
                    type: "form",
                    config: { validationVisibility: 'dirty' },
                    "submit-label": _ctx.$t('next'),
                    onSubmit: submitHandler
                }, {
                    default: (0, vue_2.withCtx)(function () { return [
                        (0, vue_2.createVNode)(_component_FormKit, {
                            modelValue: (0, vue_2.unref)(use_messageData_1.isShoe),
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) { return ((0, vue_2.isRef)(use_messageData_1.isShoe) ? (use_messageData_1.isShoe).value = $event : null); }),
                            name: "isShoe",
                            type: "radio",
                            label: _ctx.$t('imageUploadPage.shoeOptionsLabel'),
                            validation: "required",
                            options: [
                                { value: true, label: _ctx.$t('imageUploadPage.shoeYes') },
                                { value: false, label: _ctx.$t('imageUploadPage.shoeNo') },
                            ]
                        }, null, 8, ["modelValue", "label", "options"]),
                        (0, vue_2.createVNode)(FormKitImage_vue_1.default, {
                            modelValue: (0, vue_2.unref)(use_messageData_1.articleImage),
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) { return ((0, vue_2.isRef)(use_messageData_1.articleImage) ? (use_messageData_1.articleImage).value = $event : null); }),
                            name: "articleImage",
                            label: _ctx.$t('imageUploadPage.articleLabel'),
                            plugins: [(0, vue_2.unref)(use_trackingErrors_1.useErrorTracking)().trackingPlugin],
                            validation: "required"
                        }, null, 8, ["modelValue", "label", "plugins"]),
                        (0, vue_2.createVNode)(FormKitImage_vue_1.default, {
                            modelValue: (0, vue_2.unref)(use_messageData_1.defectImage),
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) { return ((0, vue_2.isRef)(use_messageData_1.defectImage) ? (use_messageData_1.defectImage).value = $event : null); }),
                            name: "defectImage",
                            label: _ctx.$t('imageUploadPage.defectLabel'),
                            plugins: [(0, vue_2.unref)(use_trackingErrors_1.useErrorTracking)().trackingPlugin],
                            validation: "required"
                        }, null, 8, ["modelValue", "label", "plugins"]),
                        (0, vue_2.createVNode)(TransitionExpand_vue_1.default, null, {
                            default: (0, vue_2.withCtx)(function () { return [
                                ((0, vue_2.unref)(use_messageData_1.isShoe))
                                    ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(FormKitImage_vue_1.default, {
                                        key: 0,
                                        modelValue: (0, vue_2.unref)(use_messageData_1.soleImage),
                                        "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) { return ((0, vue_2.isRef)(use_messageData_1.soleImage) ? (use_messageData_1.soleImage).value = $event : null); }),
                                        name: "soleImage",
                                        label: _ctx.$t('imageUploadPage.soleLabel'),
                                        plugins: [(0, vue_2.unref)(use_trackingErrors_1.useErrorTracking)().trackingPlugin],
                                        validation: "required"
                                    }, null, 8, ["modelValue", "label", "plugins"]))
                                    : (0, vue_2.createCommentVNode)("", true)
                            ]; }),
                            _: 1
                        }),
                        ((0, vue_2.unref)(ocm_object_1.isClassicSite))
                            ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(FormKitImage_vue_1.default, {
                                key: 0,
                                modelValue: (0, vue_2.unref)(use_messageData_1.receiptImage),
                                "onUpdate:modelValue": _cache[4] || (_cache[4] = function ($event) { return ((0, vue_2.isRef)(use_messageData_1.receiptImage) ? (use_messageData_1.receiptImage).value = $event : null); }),
                                name: "receiptImage",
                                label: _ctx.$t('imageUploadPage.receiptLabel'),
                                validation: "required"
                            }, null, 8, ["modelValue", "label"]))
                            : (0, vue_2.createCommentVNode)("", true),
                        (0, vue_2.createVNode)(FormKitImage_vue_1.default, {
                            modelValue: (0, vue_2.unref)(use_messageData_1.optionalImage1),
                            "onUpdate:modelValue": _cache[5] || (_cache[5] = function ($event) { return ((0, vue_2.isRef)(use_messageData_1.optionalImage1) ? (use_messageData_1.optionalImage1).value = $event : null); }),
                            name: "optionalImage1",
                            label: _ctx.$t('imageUploadPage.anotherPictureOptional'),
                            plugins: [(0, vue_2.unref)(use_trackingErrors_1.useErrorTracking)().trackingPlugin]
                        }, null, 8, ["modelValue", "label", "plugins"]),
                        (0, vue_2.createVNode)(FormKitImage_vue_1.default, {
                            modelValue: (0, vue_2.unref)(use_messageData_1.optionalImage2),
                            "onUpdate:modelValue": _cache[6] || (_cache[6] = function ($event) { return ((0, vue_2.isRef)(use_messageData_1.optionalImage2) ? (use_messageData_1.optionalImage2).value = $event : null); }),
                            name: "optionalImage2",
                            label: _ctx.$t('imageUploadPage.anotherPictureOptional'),
                            plugins: [(0, vue_2.unref)(use_trackingErrors_1.useErrorTracking)().trackingPlugin]
                        }, null, 8, ["modelValue", "label", "plugins"]),
                        ((0, vue_2.unref)(use_imageUpload_1.imageUploadSizeOverLimit))
                            ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                                (0, vue_2.createVNode)(SvgIcon_vue_1.default, {
                                    class: "mr-1 h-4 w-4",
                                    "icon-name": "info"
                                }),
                                (0, vue_2.createElementVNode)("span", null, (0, vue_2.toDisplayString)(_ctx.$t("imageUploadPage.fileSizeLimitInfo")), 1)
                            ]))
                            : (0, vue_2.createCommentVNode)("", true)
                    ]; }),
                    _: 1
                }, 8, ["submit-label"])
            ]));
        };
    }
});
