"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pushGAevent = void 0;
var dataLayer_js_1 = require("@ocm/services/dataLayer.js");
var pushGAevent = function (action, label) {
    (0, dataLayer_js_1.default)({
        event: "GAevent",
        event_category: "contactcenter",
        event_action: action,
        event_label: label,
    });
};
exports.pushGAevent = pushGAevent;
