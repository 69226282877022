import { computed, ref, Ref } from "vue";
import {
	articleImage,
	defectImage,
	optionalImage1,
	optionalImage2,
	receiptImage,
	soleImage,
	isShoe,
} from "@/composables/use-messageData";
import { EmailImage } from "@/types/email.type";
import { FileUpload } from "@/types/image.type";
import Compressor from "compressorjs";

export const invalidTypeFieldNames = ref(new Set());
export const isAnyImageCompressing = ref(false);

export async function compressImage(image: File): Promise<File | null> {
	return new Promise((resolve, reject) => {
		if (image) {
			new Compressor(image, {
				quality: 0.5,
				maxWidth: 4000,
				maxHeight: 4000,
				checkOrientation: false,
				convertSize: 0, // convert all sizes. See TUB-21097
				success(result: File) {
					resolve(result);
				},
				error(error) {
					console.log(error.message);
					reject(error);
				},
			});
		}
	});
}

export const formatFileSize = (bytes: number) => {
	const sufixes = ["B", "kB", "MB", "GB", "TB"];
	const i = Math.floor(Math.log(bytes) / Math.log(1000));
	return `${(bytes / Math.pow(1000, i)).toFixed(2)} ${sufixes[i]}`;
};

const getFileSize = (input: Ref<FileUpload[]>) => {
	if (input?.value[0]) return input?.value[0]?.file?.size;
	return 0;
};

export const imageUploadSizeOverLimit = computed(() => {
	if (isAnyImageCompressing.value) return false;
	const shoeSize = isShoe.value ? getFileSize(soleImage) : 0;
	const size =
		getFileSize(articleImage) +
		getFileSize(defectImage) +
		shoeSize +
		getFileSize(receiptImage) +
		getFileSize(optionalImage1) +
		getFileSize(optionalImage2);
	return size > 5 * 1024 * 1024; // 5 MB in bytes
});

const getBase64 = (image: Ref<FileUpload[]>): Promise<string | ArrayBuffer | null> =>
	new Promise((resolve, reject) => {
		const file = image.value[0]?.file;
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = (): void => resolve(reader.result);
		reader.onerror = (error): void => reject(error);
	});

export const getEmailImages = async (Images: Array<Ref<FileUpload[]>>): Promise<EmailImage[]> => {
	const images: EmailImage[] = [];
	for (const image of Images) {
		const imageValue = image.value[0];
		if (imageValue?.file && imageValue?.name) {
			const imageBase64: string | ArrayBuffer | null = await getBase64(image);
			const objJsonStr = JSON.stringify(imageBase64);
			images.push(setEmailImage(imageValue?.name, btoa(objJsonStr).toString()));
		}
	}
	return images;
};

export const acceptedFiles = ".jpg, .jpeg, .png";

const setEmailImage = (name: string, img: string | ArrayBuffer | null): EmailImage =>
	<EmailImage>{
		name,
		type: "article",
		img,
	};
