"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = { class: "mb-4 text-lg font-semibold" };
var FaqSearch_vue_1 = require("@/components/FaqSearch.vue");
var BaseAnnouncement_vue_1 = require("@/components/BaseAnnouncement.vue");
var BasePublications_vue_1 = require("@/components/BasePublications.vue");
var use_polly_1 = require("@/composables/use-polly");
var vue_3 = require("vue");
var router_1 = require("@/router");
exports.default = (0, vue_1.defineComponent)({
    __name: 'SelectionPage',
    setup: function (__props) {
        var searchParam = (0, vue_3.computed)(function () {
            var searchParamLocal = router_1.default.currentRoute.value.query.search;
            if (searchParamLocal && typeof searchParamLocal === "object")
                return searchParamLocal[0];
            return searchParamLocal;
        });
        (0, vue_3.onBeforeMount)(function () {
            use_polly_1.currentCollection.value = undefined;
        });
        return function (_ctx, _cache) {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", null, [
                (0, vue_2.createElementVNode)("p", _hoisted_1, (0, vue_2.toDisplayString)(_ctx.$t("selectionPage.headline")), 1),
                (0, vue_2.createVNode)(FaqSearch_vue_1.default, { "search-param": searchParam.value }, null, 8, ["search-param"]),
                (0, vue_2.createVNode)(BaseAnnouncement_vue_1.default),
                (0, vue_2.createVNode)(BasePublications_vue_1.default)
            ]));
        };
    }
});
